import React, { useState, useEffect } from "react";
import "./index.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import Form from "./components/Form";
import Modal from "./components/Modal";
import SenderInfo from "./components/SenderInfo";
import UnderConstruction from "./components/UnderConstruction";
import Navbar from "./components/Navbar";
import Home from "./components/Home";
import About from "./components/About";
import FAQs from "./components/FAQs";
import Contact from "./components/Contact";
import PrivacyPolicy from "./components/PrivacyPolicy";
import MessageBox from "./components/MessageBox";
import Footer from "./components/Footer";

function App() {
  const [sender, setSender] = useState({ name: "", email: "" });
  const [submited, setSubmited] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  const handleResize = (e) => {
    e.preventDefault();
    const isMobile = window.innerWidth < 768;
    setIsMobile(isMobile);
  };

  useEffect(() => {
    //check screen width
    const isMobile = window.innerWidth < 768;
    isMobile && setIsMobile(isMobile);

    //setup resize listenner
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      {showModal && <Modal showModal={setShowModal} submited={submited} />}

      <Router>
        <Switch>
          <Route path="/vipmvr" exact>
            <section className="w-screen bg-gradient-to-r pb-4 from-blue-400 via-blue-500 to-blue-700 text-gray-50">
              <div className="container min-h-screen flex flex-col gap-2 mx-auto px-8">
                <AnimatePresence exitBeforeEnter>
                  {sender.name && sender.email ? (
                    <Form
                      setSubmited={setSubmited}
                      showModal={setShowModal}
                      sender={sender}
                      setSender={setSender}
                      subject="VIP PARKING SOLUTIONS"
                    />
                  ) : (
                    <SenderInfo sender={sender} setSender={setSender} />
                  )}
                </AnimatePresence>
              </div>
            </section>
          </Route>

          <Route path="/swmvr" exact>
            <section className="w-screen bg-gradient-to-r pb-4 from-blue-400 via-blue-500 to-blue-700 text-gray-50">
              <div className="container min-h-screen flex flex-col gap-2 mx-auto px-8">
                <AnimatePresence exitBeforeEnter>
                  {sender.name && sender.email ? (
                    <Form
                      setSubmited={setSubmited}
                      showModal={setShowModal}
                      sender={sender}
                      setSender={setSender}
                      subject="SOUTHWEST VALET"
                    />
                  ) : (
                    <SenderInfo sender={sender} setSender={setSender} />
                  )}
                </AnimatePresence>
              </div>
            </section>
          </Route>

          <Route path="/">
            <AnimatePresence exitBeforeEnter>
              {showMessage && <MessageBox />}
            </AnimatePresence>

            <Navbar isMobile={isMobile} />
            <Switch>
              <Route path="/new/privacy-policy" exact>
                <PrivacyPolicy />
              </Route>
              <Route>
                <Home isMobile={isMobile} />
                <About isMobile={isMobile} />
                <FAQs isMobile={isMobile} />
                <Contact isMobile={isMobile} showMessage={setShowMessage} />
              </Route>
            </Switch>
            <Footer />
          </Route>

          <Route>
            <UnderConstruction />
          </Route>
        </Switch>
      </Router>
    </>
  );
}

export default App;
