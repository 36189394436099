import React from 'react'
import { Button } from './App.elements';
import { motion, AnimatePresence } from 'framer-motion';

const Modal = ({showModal, submited}) => {
    return (
        <div className="fixed flex justify-center items-center bottom-0 left-0 top-0 right-0 w-100 h-100 overflow-auto z-50 bg-black bg-opacity-80">
          <AnimatePresence exitBeforeEnter>
            <motion.div className="flex flex-col gap-4 items-center justify-center p-6"
              initial={{ y: '-100%' }}
              animate={{ y: 0 }}
              exit={{ y: '-100%' }}
              transition={{ duration: 0.5 }}
            >
              {!submited ? (
              <div>
                  <img
                      className='w-16 h-16 mx-auto'
                      src={require('../assets/spinner.gif').default}
                  />
                  <p className='text-gray-50 text-center font-semibold mt-8'>Generating Application...</p>  
              </div>
          
              ) : (
                <>
                  <motion.div className='flex flex-col justify-center items-center gap-4'
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5 }}
                  >
                    <p className='text-gray-50'>Your application has been submited</p>
                      <Button 
                        className='border-blue-300 text-gray-50 hover:bg-blue-300'
                        onClick={() => showModal(false)}
                      >Close</Button>
                  </motion.div>
                </>
              )}
          
            </motion.div>
          </AnimatePresence>
        </div>
    )
}

export default Modal
