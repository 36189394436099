export const emailjsConfig = {
    SERVICE_ID: 'service_skmf82d',
    TEMPLATE_ID: 'template_uk189bi',
    USER_ID: 'user_pKdxWnUs3yYbWoS5C0muU'
}

export const siteConfig = {
    SERVICE_ID: 'service_skmf82d',
    TEMPLATE_ID: 'template_owqon9w',
    USER_ID: 'user_pKdxWnUs3yYbWoS5C0muU'
}
