import React, { useState, useRef } from "react";
import { states } from "./states";
import SignatureCanvas from "react-signature-canvas";
import { pdf, PDFViewer } from "@react-pdf/renderer";
import MyDocument from "./MyDocument";
import { motion } from "framer-motion";
import emailjs, { init } from "emailjs-com";
import { emailjsConfig } from "../config";
import { InputWrapper, Input, Button, Label } from "./App.elements";

const Form = ({ setSubmited, showModal, sender, setSender, subject }) => {
  const [formData, setFormData] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
    previous_name: "",
    dl_number: "",
    dl_state: "",
    date_birth: "",
    email: "",
    special_state: false,
    signature: "",
  });
  const signature_ref = useRef({});

  const formatDl = (dl_number) => {
    const value = dl_number.toString();
    return capitalize(value.replace(/[^a-z0-9]/gi, ""));
  };

  const capitalize = (value) => {
    return value.toUpperCase();
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleStateChange = (e) => {
    const special_states = [
      "california",
      "minnesota",
      "massachusetts",
      "maine",
      "oklahoma",
    ];
    let special_state = false;
    if (special_states.includes(e.target.value.toLowerCase())) {
      special_state = true;
    }
    setFormData({
      ...formData,
      dl_state: e.target.value,
      special_state: special_state,
    });
  };

  const generatePdf = (e) => {
    e.preventDefault();
    showModal(true);

    //format data before generating pdf file.
    formData.first_name = capitalize(formData.first_name);
    formData.middle_name = capitalize(formData.middle_name);
    formData.last_name = capitalize(formData.last_name);
    formData.dl_number = formatDl(formData.dl_number);

    //accees blob file
    const file = pdf(<MyDocument formData={formData} />);
    file.toBlob().then((blob) => {
      const reader = new FileReader();
      //convert blob to base64
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64 = reader.result;
        const { SERVICE_ID, TEMPLATE_ID, USER_ID } = emailjsConfig;
        const template_params = {
          to_name: "Zack",
          sender_name: sender.name,
          sender_email: sender.email,
          user_lname: formData.last_name,
          subject: `${subject.toUpperCase()} driver check ${formData.last_name}`,
          file_name:
            (formData.first_name + " " + formData.last_name).toUpperCase() +
            " MVR FORM.pdf",
          content: base64,
        };
        init(USER_ID);
        emailjs
          .send(SERVICE_ID, TEMPLATE_ID, template_params, USER_ID)
          .then((result) => {
            e.target.reset();
            signature_ref.current.clear();
            setSubmited(true);
            setSender({ name: "", email: "" });
          })
          .catch((error) => {
            console.error(error);
          });
      };
    });
  };

  return (
    <motion.form
      className="grid grid-cols-1 justify-center items-end gap-4 lg:grid-cols-9"
      onSubmit={generatePdf}
      initial={{ y: "100%" }}
      animate={{ y: 0 }}
      exit={{ y: "100%" }}
      transition={{ duration: 0.5 }}
    >
      <h1 className="text-3xl sm:text-4xl md:text-5xl col-start-1 col-span-full">
        MVR Check application
      </h1>
      <InputWrapper
        id="first_name"
        className="col-start-1 col-span-full lg:col-start-1 lg:col-span-3"
      >
        <Label htmlFor="first_name">Name</Label>
        <Input type="text" name="first_name" onChange={handleChange} required />
      </InputWrapper>

      <InputWrapper
        id="middle_name"
        className="col-start-1 col-span-full lg:col-start-4 lg:col-span-3"
      >
        <Label htmlFor="middle_name">Middle Name</Label>
        <Input type="text" name="middle_name" onChange={handleChange} />
      </InputWrapper>

      <InputWrapper
        id="last_name"
        className="col-start-1 col-span-full lg:col-start-7 lg:col-span-3"
      >
        <Label htmlFor="last_name">Last Name</Label>
        <Input type="text" name="last_name" onChange={handleChange} required />
      </InputWrapper>

      <InputWrapper
        id="previous_name"
        className="col-start-1 col-span-full lg:col-start-1 lg:col-span-3"
      >
        <Label htmlFor="previous_name">Maiden Name, if any</Label>
        <Input type="text" name="previous_name" onChange={handleChange} />
      </InputWrapper>

      <InputWrapper
        id="email"
        className="col-start-1 col-span-full lg:row-start-3 lg:row-span-1 lg:col-start-4 lg:col-span-3 "
      >
        <Label htmlFor="email">Email</Label>
        <Input type="email" name="email" onChange={handleChange} required />
      </InputWrapper>

      <InputWrapper
        id="date_birth"
        className="col-start-1 col-span-full lg:row-start-3 lg:row-span-1 lg:col-start-7 lg:col-span-full"
      >
        <Label htmlFor="date_birth">Date of Birth</Label>
        <Input
          type="date"
          name="date_birth"
          placeholder="dd-mm-yyyy"
          onChange={(e) => {
            e.preventDefault();
            const date = e.target.value.split("-");
            const year = date[0];
            const month = date[1];
            const day = date[2];
            setFormData({ ...formData, date_birth: `${month}/${day}/${year}` });
          }}
          required
        />
      </InputWrapper>

      <InputWrapper
        id="dl_number"
        className="col-start-1 col-span-full lg:row-start-4 lg:row-span-1 lg:col-start-3 lg:col-span-2"
      >
        <Label htmlFor="dl_number">Driver's license Number</Label>
        <Input type="text" name="dl_number" onChange={handleChange} required />
      </InputWrapper>

      <InputWrapper
        id="dl_state"
        className="align-self-stretch h-full col-start-1 col-span-full lg:row-start-4 lg:row-span-1 lg:col-start-1 lg:col-span-2"
      >
        <Label htmlFor="dl_state">License State</Label>
        <select
          className="px-3 py-3 text-gray-500 h-full w-full"
          name="dl_state"
          onChange={handleStateChange}
          required
        >
          <option value="">Select</option>
          {states.map((state, index) => (
            <option key={index} value={state}>
              {state}
            </option>
          ))}
        </select>
      </InputWrapper>

      {/* <div id='special_state' className='flex flex-row-reverse gap-4 py-2 items-center col-start-1 col-span-full lg:row-start-4 lg:row-span-1 lg:col-start-5 lg:col-span-full'>
            <label htmlFor='special_state' className='text-sm'>
              ***California, Minnesota, Massachusetts, Maine and Oklahoma Applicants: please check this box and provide your email address to have a copy of your report emailed directly to you.
            </label>
            <input type="checkbox" style={{zoom: 2}}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  special_state: e.target.checked
                })
            }} />
          </div> */}

      <InputWrapper className="col-start-1 col-span-full">
        <Label>Signature</Label>
        <div className="bg-gray-100 h-64 w-full col-start-1 col-span-full shadow-md">
          <SignatureCanvas
            ref={signature_ref}
            penColor="blue"
            canvasProps={{ className: "w-full h-full" }}
            onEnd={() => {
              setFormData({
                ...formData,
                signature: signature_ref.current
                  .getTrimmedCanvas()
                  .toDataURL("image/png"),
              });
            }}
          />
        </div>
        {formData.signature && (
          <a
            href="#"
            className="font-bold text-red-500"
            onClick={(e) => {
              e.preventDefault();
              signature_ref.current.clear();
            }}
          >
            Clear signature
          </a>
        )}
      </InputWrapper>

      {formData.signature && (
        <>
          {/* <PDFViewer style={{width: 800, height: 800}}>
              <MyDocument formData={formData} />
            </PDFViewer> */}

          <div className="col-start-1 col-span-full flex flex-col gap-4">
            <p>
              <strong>Notice to California Applicants:</strong> Under section
              1786.22 of California Civil Code, you have the right to request
              from Embark Safety LLC, upon proper identification, the nature and
              substance of all information in files pertaining to you, including
              the sources of information, and recipients of any reports on you,
              which Embark Safety LLC has previously furnished within the
              two-year period preceding your request. You may view the file
              maintained on you by Embark Safety LLC during normal business
              hours. You may also obtain a copy of this file upon submitting
              proper identification. Upon making a written request, you may
              receive a summary of your report.
            </p>
            <p>
              <strong>Notice to Maine Applicants:</strong> Under Chapter 210
              Section 1314 of Maine revised Statutes, you have the right, upon
              request, to be informed within 5 business days of such a request
              to whether or not an investigative consumer report was requested.
              If such report was obtained, you may contact the Consumer
              Reporting Agency and request a copy.
            </p>
            <p>
              <strong>Notice to Massachusetts Applicants:</strong> Under Mass.
              Ann. Laws chapter. 93 §§ 50, a Consumer Reporting Agency may
              furnish a report if intended to be utilized for employment
              purposes.
            </p>
          </div>

          <div className="w-full flex justify-between col-start-1 col-span-full">
            <Button
              className="border-red-300 hover:bg-red-200"
              onClick={() => setSender({ name: "", email: "" })}
            >
              Go Back
            </Button>

            <Button
              type="submit"
              className="border-green-300 hover:bg-green-300"
            >
              Send
            </Button>
          </div>
        </>
      )}
    </motion.form>
  );
};

export default Form;
