import React from 'react';
import { motion } from 'framer-motion';

const PreviousUsers = ({setSender, setUsers, users}) => {
    return (
        <motion.div 
            className='mx-auto px-8 w-full flex flex-col justify-center items-center gap-4'
            initial={{ x: '-100%' }}
            animate={{ x: 0 }}
            exit={{ x: '100%' }}
            transition={{ duration: 0.3 }}
        >
            {users.map((user, index) => (
                <div key={index} 
                    className='flex bg-blue-800 border-2 border-green-400 px-4 py-2 w-full shadow-lg items-center gap-4 rounded-3xl hover:bg-blue-500 cursor-pointer'
                    onClick={(e) => {
                        e.preventDefault();
                        setSender(user)
                    }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clipRule="evenodd" />
                    </svg>
                    <div>
                        <p>{user.name}</p>
                        <p>{user.email}</p>
                    </div>
                </div>
            ))}
            <p>Not you? <a href='#'
                className='text-green-400 underline' 
                onClick={(e) => {
                    e.preventDefault();
                    setUsers([])
                }}>click here</a>
            </p>
        </motion.div>
    )
}

export default PreviousUsers
