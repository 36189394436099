import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom';
import { Container } from './App.elements';
import { motion, useAnimation } from 'framer-motion';

const List = ({children, showMenu, setShowMenu, isMobile}) => {
    const animation = useAnimation();

    //effect to show/hide menu
    useEffect(() => {
        //motion variants
        const variants = {
            visible: { x: 0 },
            hidden: { x: '200vh' }
        }

        const setScroll = (scroll) => {
            document.body.style.overflow = scroll;
        }

        //only animates it if is mobile
        if(isMobile){
            showMenu ? animation.start(variants.visible) : animation.start(variants.hidden);
            //block screen from scrolling when menu is open
            showMenu ? setScroll('hidden') : setScroll('auto')
        }else{
            //always visibe on large screens
            animation.start(variants.visible);
            //allows to scroll
            document.body.style.overflow = 'auto'
        }

    }, [showMenu])

    useEffect(() => {
        const list = document.getElementById('list');
        const hideNavbar = (e) => {
            //svg and path because of the email icon amd burger icon
            const tagNames = ['A', 'svg', 'path']
            tagNames.includes(e.target.tagName) && setShowMenu(false);
        }

        // close navbar when click in a link
        list.addEventListener('click', hideNavbar);

        return () => list.removeEventListener('click', hideNavbar);
    }, [])

    return (
        <motion.ul
            id='list'
            className={`
                fixed top-0 z-50 flex flex-col w-full h-screen justify-center gap-4 items-center bg-blue-800 top-0 left-0
                md:relative md:flex md:flex-row md:h-auto md:bg-opacity-0 md:justify-start ld:bg-none
            `}
            animate={ animation }
            transition={{ duration: 0.5 }}
   
    >
            {children}
        </motion.ul>
    )
}

const Item = (props) => (
    <motion.li className='p-2'
        whileTap={{ scale: 0.9 }}
        transition={{ duration: 0.3 }}
    >
        <a {...props} 
            className='lg:text-lg uppercase font-bold text-gray-50 hover:text-green-400'
        >
            {props.children}
        </a>
    </motion.li>
)

const IconItem = () => {
    const [isHovering, setIsHovering] = useState(false);

    return (
        <li className='p-2 bg-gray-50 rounded-full h-12 w-12 cursor-pointer' onMouseEnter={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)} >
            <a href="/new#contact">
                <svg id="email" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 23.26 16.103">
                    <path id="Path_27" data-name="Path 27" d="M26.445,10.348l-6.016,6.128a.108.108,0,0,0,0,.157l4.21,4.484a.726.726,0,0,1,0,1.029.729.729,0,0,1-1.029,0l-4.193-4.467a.115.115,0,0,0-.162,0l-1.023,1.04a4.5,4.5,0,0,1-3.209,1.353,4.592,4.592,0,0,1-3.277-1.392l-.984-1a.115.115,0,0,0-.162,0L6.405,22.146a.729.729,0,0,1-1.029,0,.726.726,0,0,1,0-1.029l4.21-4.484a.119.119,0,0,0,0-.157L3.565,10.348a.11.11,0,0,0-.19.078V22.688a1.794,1.794,0,0,0,1.789,1.789H24.846a1.794,1.794,0,0,0,1.789-1.789V10.426A.112.112,0,0,0,26.445,10.348Z" transform="translate(-3.375 -8.374)" fill={isHovering ? '#34D399' : '#1E3A8A' }/>
                    <path id="Path_28" data-name="Path 28" d="M15.179,18.113a3.04,3.04,0,0,0,2.186-.917l8.773-8.929a1.757,1.757,0,0,0-1.107-.391H5.333a1.746,1.746,0,0,0-1.107.391L13,17.2A3.04,3.04,0,0,0,15.179,18.113Z" transform="translate(-3.549 -7.875)" fill={isHovering ? '#34D399' : '#1E3A8A' }/>
                </svg>
            </a>
        </li>
    )
}

const Burger = (props) => (
    <div className='p4 ml-auto h-12 w-12 md:hidden cursor-pointer' {...props} >
        <svg id="burger" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 44 30">
            <rect id="Rectangle_19" data-name="Rectangle 19" width="44" height="6" rx="3" fill="#34D399"/>
            <rect id="Rectangle_19-2" data-name="Rectangle 19" width="44" height="6" rx="3" transform="translate(0 12)" fill="#34D399"/>
            <rect id="Rectangle_19-3" data-name="Rectangle 19" width="44" height="6" rx="3" transform="translate(0 24)" fill="#34D399"/>
        </svg>
    </div>
)

const Close = (props) => (
    <div className='absolute top-4 right-4 ml-auto h-12 w-12 md:hidden cursor-pointer' {...props} >
        <svg id="Layer_1" version="1.1" viewBox="0 0 512 512" height="100%" width="100%" xmlns="http://www.w3.org/2000/svg">
            <path d="M437.5,386.6L306.9,256l130.6-130.6c14.1-14.1,14.1-36.8,0-50.9c-14.1-14.1-36.8-14.1-50.9,0L256,205.1L125.4,74.5  c-14.1-14.1-36.8-14.1-50.9,0c-14.1,14.1-14.1,36.8,0,50.9L205.1,256L74.5,386.6c-14.1,14.1-14.1,36.8,0,50.9  c14.1,14.1,36.8,14.1,50.9,0L256,306.9l130.6,130.6c14.1,14.1,36.8,14.1,50.9,0C451.5,423.4,451.5,400.6,437.5,386.6z" fill='#34D399'/>
        </svg>
    </div>
)

const Navbar = ({isMobile}) => {
    const [showMenu, setShowMenu] = useState(false);
    const [transparent, setTransparent] = useState(false);
    const nav_ref = useRef();

    const toggle = () => {
        setShowMenu(!showMenu)
    }
    

    useEffect(() => {
        isMobile ? setShowMenu(false) : setShowMenu(true);
    }, [isMobile])

    useEffect(() => {
        if(nav_ref.current){
            const navbar = nav_ref.current;

            const handleScrollDown = () => {
                const scrolled = window.scrollY
                const classes = ['bg-blue-900', 'bg-opacity-90', 'shadow-lg', 'py-4']
                if(scrolled > 0){
                    navbar.classList.add(...classes)
                }else{
                    navbar.classList.remove(...classes)
                }
            }

            //add css classes to navbar when scrolled down
            document.addEventListener('scroll', handleScrollDown)

            return () => {
                document.removeEventListener('resize', handleScrollDown)
            }
        }
    }, [])

    return (
        <motion.nav className='flex w-screen items-center z-50 fixed top-0 p-8 transition-all duration-500 ease-in-out' ref={nav_ref}
            transition={{ duration: 0.3 }}
        >
            <div className='container mx-auto'>
                <List showMenu={showMenu} isMobile={ isMobile } setShowMenu={setShowMenu}>
                    <Item href='/new#home'>Home</Item>
                    <Item href='/new#about'>About</Item>
                    <Item href='/new#faqs'>FAQ's</Item>
                    <IconItem />
                    <Close />
                </List>
                <Burger onClick={toggle} />
            </div>

        </motion.nav>
    )
}

export default Navbar
