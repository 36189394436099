import React, {useState, useEffect} from 'react';
import { InputWrapper, Label, Input, Button } from './App.elements';
import PreviousUsers from './PreviousUsers';
import { motion, AnimatePresence } from 'framer-motion';

const SenderInfo = ({setSender}) => {
    const [users, setUsers] = useState([])
    const local_users = localStorage.getItem('users');

    
    const handleSubmit = (e) => {
        e.preventDefault();
        const name = e.target.sender_name.value;
        const email = e.target.sender_email.value;
        const user = { name, email }

        // check localstorage for existing user
        if(local_users){
            try{
                const users = JSON.parse(local_users);
                const found_user = users.find(user => user.email.toLowerCase() === email.toLowerCase());
                if(!found_user){
                    users.push(user)
                    localStorage.setItem('users', JSON.stringify([...users]));
                }
            }catch(error){
                console.error(error)
            }
        }else{
            localStorage.setItem('users', JSON.stringify([{name: name, email: email}]))
        } 
        setSender(user);
    }

    //check localstorage for users
    useEffect(() => {
        if(local_users){
            try{
                setUsers(JSON.parse(local_users))
            }catch(error){
                console.error(error)
                setUsers([])
            }        
        }
    }, [])

    
    return (
        <div className='my-auto lg:mx-auto lg:w-96'>
             <div className='mb-16 px-8'>
                <h1 className='text-3xl text-green-200 sm:text-4xl md:text-5xl col-start-1 col-span-full'>Submitting Manager</h1>
                <p className='mt-2'>Manager identification</p>
            </div>
            <AnimatePresence exitBeforeEnter>
                {users.length > 0 ? (
                    <PreviousUsers setSender={setSender} setUsers={setUsers} users={users}/>
                ) : (
                    <motion.form 
                        className='flex flex-col gap-4 px-4'
                        onSubmit={handleSubmit}
                        initial={{ x: '-100%' }}
                        animate={{ x: 0 }}
                        exit={{ x: '100%' }}
                        transition={{ duration: 0.3 }}
                    >           
                        <InputWrapper>
                            <Label htmlFor='sender_name'>Name</Label>
                            <Input type='text' name='sender_name' required/>
                        </InputWrapper>

                        <InputWrapper>
                            <Label htmlFor='sender_email'>Email</Label>
                            <Input type='email' name='sender_email' required/>
                        </InputWrapper>

                        <Button type='submit' className='border-green-300 hover:bg-green-300'>Proceed</Button>

                    </motion.form>
                )}
            </AnimatePresence>
        </div>
    )
}

export default SenderInfo
