import React, {useEffect} from 'react'
import { Document, Page, Font,Text, View, Image, StyleSheet } from '@react-pdf/renderer';
import { format } from 'date-fns';
import Arial from '../Fonts/5608471/413af1a3-8b25-474c-9bb6-8d89bb907596.ttf'

//Create Styles
const styles = StyleSheet.create({
    page: {
        padding: 20,
        fontFamily: 'Arial MT W01 Regular'
    },
    section: {
        width: '100%',
        flexDirection: 'column',
        borderWidth: 3,
        borderColor: 'black',
        borderStyle: 'solid',
        width: '100%',
        height: '100%'
    },
    heading: {
        borderBottomWidth: 3,
        borderColor: 'black',
        borderStyle: 'solid',
        fontSize: 12,
        fontFamily: 'Lato Bold',
        fontWeight: 700,
        backgroundColor: '#e1e1e1',
        padding: 10
    },
    content: {
        paddingHorizontal: 10
    }
});

Font.register({
    family: "Arial MT W01 Regular",
    format: 'ttf',
    src: Arial
});

Font.register({
    family: 'Open Sans',
    src: `https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFVZ0e.ttf`,
});

Font.register({
    family: 'Lato Bold',
    src: `https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh6UVSwiPHA.ttf`,
  });

const MyDocument = ({formData, signature}) => {
    return (
        <Document>
            <Page size='A4' style={styles.page}>
                <View style={styles.section}>
                    <View style={styles.heading}>
                        <Text>AUTHORIZATION FOR RELEASE OF INFORMATION FOR INSURANCE UNDERWRITING</Text>
                    </View>
                    <View style={styles.content}>
                        <Text style={{
                            fontSize: 18, 
                            paddingVertical: 10,
                            fontFamily: 'Lato Bold',
                            fontWeight: 700
                        }}>Driver Record Consent Form</Text>
                        <Text style={{fontSize: 9, lineHeight: 1.5}}>
                            I hereby authorize Embark Safety LLC and its designated agents and representatives to conduct a comprehensive review of my driver
                            record background through a consumer report and/or an investigative consumer report to be generated for insurance purposes. Upon
                            Request, Embark Safety LLC will supply a copy of the completed consumer report along with a copy of an individual’s rights under the
                            Fair Credit Reporting Act.
                        </Text>
                        <Text style={{
                            fontSize: 18, 
                            paddingVertical: 10,
                            fontFamily: 'Lato Bold',
                            fontWeight: 700
                        }}>Authorization and Release</Text>
                        <Text style={{fontSize: 9, lineHeight: 1.5}}>
                            I <Text style={{textDecoration: 'underline', paddingHorizontal: 15 ,fontFamily: 'Lato Bold', fontWeight: 700}}>{formData.first_name} {formData.middle_name} {formData.last_name}</Text> , authorize the complete release of these records or data pertaining to me which an individual,
                            company, firm, corporation, or public agency may have. I authorize the full release of the information described above, without any
                            reservation,
                            to my insurance company CoverDocs LLC . I hereby release Embark Safety LLC, LLC, and its agents, officials, representatives, or
                            assigned agencies, including officers, employees, or related personnel both individually and collectively, from any and all liability for
                            damages of whatever kind, which may at any time, result to me, my heirs, family or associates because of compliance with this
                            authorization for release form. I certify that all information provided below and on my insurance application is correct to the best of
                            my knowledge. This authorization and consent shall be valid in original, fax, or copy form. The following information is required by law
                            enforcement agencies and other entities for identification purposes when checking records. It is confidential and will not be used for
                            any other purpose.
                        </Text>

                        <View style={{
                            flexDirection: 'row',
                            paddingTop: 30,
                            alignItems: 'flex-end'
                        }}>
                            <View style={{
                                width: '25%',
                                marginLeft: 5,
                                marginRight: 5
                            }}>
                                <View style={{
                                    width: '100%',
                                    borderBottomWidth: 1,
                                    borderColor: 'black',
                                    borderStyle: 'solid',
                                }}>
                                    <Text style={{fontSize: 14 }}>{formData.first_name}</Text>
                                </View>
                                <Text style={{fontSize: 8 }}>Applicant's First Name</Text>
                            </View>

                            <View style={{
                                width: '25%',
                                marginLeft: 5,
                                marginRight: 5
                            }}>
                                <View style={{
                                    width: '100%',
                                    borderBottomWidth: 1,
                                    borderColor: 'black',
                                    borderStyle: 'solid',
                                }}>
                                    <Text style={{fontSize: 14 }}>{formData.middle_name}</Text>
                                </View>
                                <Text style={{fontSize: 8 }}>Middle Name</Text>
                            </View>

                            <View style={{
                                width: '25%',
                                marginLeft: 5,
                                marginRight: 5
                            }}>
                                <View style={{
                                    width: '100%',
                                    borderBottomWidth: 1,
                                    borderColor: 'black',
                                    borderStyle: 'solid',
                                }}>
                                    <Text style={{fontSize: 14 }}>{formData.last_name}</Text>
                                </View>
                                <Text style={{fontSize: 8 }}>Last Name</Text>
                            </View>

                            <View style={{
                                width: '25%',
                                marginLeft: 5,
                                marginRight: 5
                            }}>
                                <View style={{
                                    width: '100%',
                                    borderBottomWidth: 1,
                                    borderColor: 'black',
                                    borderStyle: 'solid',
                                }}>
                                    <Text style={{fontSize: 14 }}>{formData.previous_name}</Text>
                                </View>
                                <Text style={{fontSize: 8 }}>Maiden/AKA/Previous Name(s)</Text>
                            </View>
                        </View>

                        {/* SECOND LINE */}
                        <View style={{
                            flexDirection: 'row',
                            paddingTop: 30
                        }}>
                            <View style={{
                                width: '50%',
                                marginLeft: 5,
                                marginRight: 5
                            }}>
                                <View style={{
                                    width: '100%',
                                    borderBottomWidth: 1,
                                    borderColor: 'black',
                                    borderStyle: 'solid',
                                }}>
                                    <Text style={{fontSize: 14 }}>{formData.dl_number}</Text>
                                </View>
                                <Text style={{fontSize: 9 }}>Driver's License Number</Text>
                            </View>

                            <View style={{
                                width: '25%',
                                marginLeft: 5,
                                marginRight: 5
                            }}>
                                <View style={{
                                    width: '100%',
                                    borderBottomWidth: 1,
                                    borderColor: 'black',
                                    borderStyle: 'solid',
                                }}>
                                    <Text style={{fontSize: 14 }}>{formData.dl_state}</Text>
                                </View>
                                <Text style={{fontSize: 9 }}>License State</Text>
                            </View>

                            <View style={{
                                width: '25%',
                                marginLeft: 5,
                                marginRight: 5
                            }}>
                                <View style={{
                                    width: '100%',
                                    borderBottomWidth: 1,
                                    borderColor: 'black',
                                    borderStyle: 'solid',
                                }}>
                                    <Text style={{fontSize: 14 }}>{formData.date_birth}</Text>
                                </View>
                                <Text style={{fontSize: 9 }}>Date of Birth</Text>
                            </View>
                        </View>

                        {/* THIRD LINE */}
                        <View style={{
                            flexDirection: 'row',
                            paddingTop: 30
                        }}>
                            <View style={{
                                flexDirection: 'row',
                                width: '50%',
                                marginLeft: 5,
                                marginRight: 5
                            }}>
                                <View style={{ borderStyle: 'solid', borderColor: 'black', borderWidth: 1, height: 20, width: 20, position: 'relative'}}>
                                    {formData.special_state && (
                                        <Image 
                                            style={{position: 'absolute', width: '20px', height: '20px'}}
                                            src={require('../assets/checked.png').default}
                                        />
                                    )}
                                </View>
                                <Text style={{fontSize: 8, marginLeft: 10, width: '70%' }}>
                                    ***California, Minnesota, Massachusetts, Maine and
                                    Oklahoma Applicants: please check this box and
                                    provide your email address to have a copy of your
                                    report emailed directly to you.
                                </Text>
                            </View>

                            <View style={{
                                width: '50%',
                                marginLeft: 5,
                                marginRight: 5
                            }}>
                                <View style={{
                                    width: '100%',
                                    borderBottomWidth: 1,
                                    borderColor: 'black',
                                    borderStyle: 'solid',
                                }}>
                                    <Text style={{fontSize: 14 }}>{formData.email}</Text>
                                </View>
                                <Text style={{fontSize: 9 }}>Email</Text>
                            </View>
                            
                        </View>

                        {/* NOTICES */}
                        <View style={{
                            flexDirection: 'column',
                            paddingVertical: 30 
                        }}>
                            <Text style={{ fontSize: 9, lineHeight: 1.5 }}>
                                <Text style={{ fontSize: 9, fontFamily: 'Lato Bold', fontWeight: 700}}>Notice to California Applicants: </Text>Under section 1786.22 of California Civil Code, you have the right to request from Embark Safety
                                LLC, upon proper identification, the nature and substance of all information in files pertaining to you, including the sources of
                                information, and recipients of any reports on you, which Embark Safety LLC has previously furnished within the two-year period
                                preceding your request. You may view the file maintained on you by Embark Safety LLC during normal business hours. You may also
                                obtain a copy of this file upon submitting proper identification. Upon making a written request, you may receive a summary of your
                                report.
                            </Text>

                            <Text style={{ fontSize: 9, lineHeight: 1.5 }}>
                                <Text style={{ fontSize: 9, fontFamily: 'Lato Bold', fontWeight: 700}}>Notice to Maine Applicants: </Text>Under Chapter 210 Section 1314 of Maine revised Statutes, you have the right, upon request, to be
                                informed within 5 business days of such a request to whether or not an investigative consumer report was requested. If such report
                                was obtained, you may contact the Consumer Reporting Agency and request a copy.
                            </Text>

                            <Text style={{ fontSize: 9, lineHeight: 1.5 }}>
                                <Text style={{ fontSize: 9, fontFamily: 'Lato Bold', fontWeight: 700}}>Notice to Massachusetts Applicants: </Text>Under Mass. Ann. Laws chapter. 93 §§ 50, a Consumer Reporting Agency may furnish a
                                report if intended to be utilized for employment purposes.
                            </Text>
                        </View>

                        {/* Last Row */}
                        <View style={{
                            flexDirection: 'row',
                            marginTop: '30px',
                            marginBottom: 'auto',
                            alignItems: 'flex-end'
                        }}>
                            <View style={{
                                width: '70%',
                                position: 'relative'
                            }}>
                                <Image 
                                    src={formData.signature}
                                    style={{
                                        maxHeight: 50,
                                        maxWidth: '50%',
                                        marginLeft: 20,
                                        marginRight: 'auto',
                                    }}
                                />
                                <Text style={{
                                    fontSize: 9 ,
                                    borderTopWidth: 1,
                                    borderColor: 'black',
                                    borderStyle: 'solid',
                                    marginLeft: 5,
                                    marginRight: 5
                                    
                                }}>Signature</Text>
                                <Text style={{fontSize: 7 }}>(Electronic signatures are NOT acceptable - This document must be physically signed by applicant)</Text>
                            </View>

                            <View style={{
                                width: '30%',

                            }}>
                                <Text style={{
                                    fontSize: 9 ,
                                    borderTopWidth: 1,
                                    borderColor: 'black',
                                    borderStyle: 'solid',
                                    marginLeft: 5,
                                    marginRight: 5
                                }}>Date</Text>
                                <Text style={{fontSize: 7 }}> </Text>
                    
                            </View>

                        </View>

                    </View>
                </View>
            </Page>
            
        </Document>
    )
}

export default MyDocument
