import React from "react";
import { Container } from "./App.elements";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="p-4 bg-blue-900 bg-opacity-1 text-gray-50">
      <Container className="flex gap-4">
        <p>CoverDocs.© 2021</p>
        <Link to="/new/privacy-policy" className="underline">
          Privacy Policy
        </Link>
        <span className="ml-auto">
          Designed by <a href="#">Eliezer Barbosa</a>
        </span>
      </Container>
    </footer>
  );
};

export default Footer;
