import { motion } from 'framer-motion';

export const InputWrapper = ({children, className}) => (
    <div className={`flex flex-col w-full items-start ${className}`}>
        {children}
    </div>
)

export const Label = (props) => (
    <labe className='text-gray-50 font-semibold ' {...props}>{props.children}</labe>
)

export const Input = (props) => (
    <input className='w-full px-3 py-3 border-2 bg-transparent border-blue-300 outline-none focus:border-green-200 ' {...props}/>
)

export const Button = ({children, className, onClick}) => {
    return (
        <button className={`px-8 py-2 border-2 uppercase shadow-md font-bold rounded-full ${className}`} onClick={onClick}>
            {children}
        </button>
    )
}

export const Container = ({children, className}) => (
    <div className={`container px-8 lg:px-4 mx-auto ${className && className}`}>
        {children}
    </div>
)

export const SiteButton = (props) => (
    <motion.button 
        className='px-4 py-2 text-gray-50 text-sm uppercase rounded-full border-2 border-gray-50 flex gap-4 w-max items-center shadow-lg lg:text-md lg:py-2 lg:px-6 hover:border-green-400' 
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        {...props}
    >
        {props.children}
        <img 
            className=''
            src={require('../assets/right-arrow.png').default}
            alt='arrow'
        />
    </motion.button>
)

export const HomeCard = ({imgUrl, onClick}) => (
    <motion.div 
        className='border-2 border-wite rounded-3xl p-8 w-max cursor-pointer'
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        onClick={onClick}
        layout="home_card"
    >
        <img
            className='h-8' 
            src={imgUrl} 
            alt='home'
        />
    </motion.div>
)

export const Backdrop = ({children, ref, onClick}) => (
    <motion.div 
        id='backdrop' 
        className="fixed w-screen h-screen bg-gray-800 bg-opacity-80 z-50 flex jusify-center items-center" 
        ref={ref}
        onClick={onClick}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
    >
        {children}
    </motion.div>
)