import React, {useState, useEffect} from 'react';
import { Container } from './App.elements';
import { motion, useAnimation ,AnimateSharedLayout, AnimatePresence } from 'framer-motion';
import { useInView } from 'react-intersection-observer';


const Answer = ({answer}) => (
    <motion.p className='p-2 text-gray-300 text-md md:p-4 md:text-lg'
        layout
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
    >
        {answer}
    </motion.p>
)

const ArrowDown = () => (
    <motion.div className='w-24w' layout>
        <img src={require('../assets/arrow-down.svg').default} style={{ width: '20px'}} alt='expand'/>
    </motion.div>
)

const Question = ({question, answer}) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = (e) => {
        setIsOpen(!isOpen)
    }

    return (
        <motion.li 
            className={`
                p-2 md:p-4 border-2 border-gray-50 cursor-pointer z-40 hover:border-green-400 hover:bg-blue-800 hover:bg-opacity-95
                ${isOpen ? 'absolute border-green-400 bg-blue-800 z-50 bg-opacity-95 rounded-tl-3xl rounded-bl-3xl p-2 md:p-8' : 'rounded-tl-3xl rounded-bl-3xl'}
            `}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            transition={{ type: "tween", stiffness: 500 }}
            onClick={toggle}
            layout
        >
            <motion.div className='flex gap-4 items-center' layout>
                <ArrowDown />
                <motion.p className='text-gray-50 text-md md:text-xl' layout>{question}</motion.p>
            </motion.div>
            <AnimatePresence>
                {isOpen && (
                    <Answer answer={answer} />
                )}
            </AnimatePresence>
        </motion.li>
    )
}

const FAQs = ({isMobile}) => {
    const {ref, inView} = useInView({ threshold: 0.2 })
    const animation = useAnimation();

    useEffect(() => {
        if(inView){
            //if element is in view
            animation.start({
                opacity: 1,
                transition: { duration: 0.7 }
            })
        }else{
            animation.start({ 
                opacity: 0
            })
        }
    }, [inView])

    const faqs = [
        {
            question: 'Do I have a choice when it comes to the annual rate increases of the insurance companies?',
            answer: `Every company's rates will vary for different specialties, because every company will underwrite differently. It is smart to look at your options at renewal to see what is available to you.
            Further, the tort reform changes in many states, in addition to certain options offered by malpractice insurance companies, make it even more beneficial to look around and see if there are better available options.`
        },
        {
            question: ' Will all insurance companies defend my potential claims with the same manner of expertise?',
            answer: `You purchase insurance so that you don't have to worry. You want protection and security. The most important thing to consider when choosing a company is their financial stability and health, because a company built on weak financial foundations is not one that can provide you with protection and security when you need it. Insurance is not worth much if there is a chance it won't be there to cover you when you need it, so the priority should be to find a company that is financially strong and has the funds and backing to protect you in the court room.`
        },
        {
            question: 'I need a tail-only policy. Will any malpractice insurance company sell me tail coverage?',
            answer: `Having one or two past claims won't disqualify you from getting quotes from other companies. Malpractice insurance companies are informed and willing to take into consideration the medical facts surrounding past claims. It is possible to document past claim events in such a way that your side of the story is considered, especially using an experienced malpractice insurance professional. This is an instance where having the quality guidance of an experienced malpractice insurance professional who will represent your interest with a variety of companies is much more beneficial than doing it on your own, or exploring only one company.`
        },
        {
            question: ` I've had a claim - am I now stuck with my malpractice insurance company? If I change to a new one will I experience difficulties getting insurance in the future or even have to pay a surcharge?`,
            answer: `Tail coverage is only necessary if you are currently covered by a claims-made policy.  It provides extended coverage for your acts while your claims-made policy was in force.  Tail coverage is only available through the insurance carrier from which you previously received coverage.`
        }
    ];

    return (
        <section className='relative w-screen h-max md:h-screen bg-template overflow-hidden' id='faqs'>
            <div className='flex w-full absolute left-0 top-0 h-72 z-0 md:w-5/12 md:h-full md:flex-col md:justify-end'>
                {!isMobile ? (
                    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 770 764" preserveAspectRatio='none'>
                        <path id="Subtraction_1" data-name="Subtraction 1" d="M-652.643-1984.5h-779v-2.281h779v2.281Zm-779-3.142V-2751.5h779v.148H-884.194l.012.011c.755.856,7.656,8.793,17.456,23.775,6.033,9.223,11.956,19.26,17.6,29.83,7.059,13.214,13.7,27.3,19.751,41.875,15.394,37.1,27.056,77.52,34.662,120.147a686.6,686.6,0,0,1,9.267,78.4c.878,14.359,1.343,29.1,1.383,43.812.042,15.382-.381,31.16-1.255,46.9a161.043,161.043,0,0,1-2.9,22.682,168.855,168.855,0,0,1-6,21.958,186.19,186.19,0,0,1-8.9,21.237,213.128,213.128,0,0,1-11.6,20.518c-4.228,6.613-8.975,13.276-14.108,19.8-5.015,6.376-10.538,12.8-16.415,19.088a389.265,389.265,0,0,1-38.956,36.044,551.043,551.043,0,0,1-45.8,33.217c-15.7,10.256-32.879,20.488-51.061,30.41-17.094,9.33-35.509,18.623-54.732,27.623-17.96,8.408-37.075,16.771-56.815,24.855-35.165,14.4-73.363,28.358-113.532,41.483-33.012,10.787-67.605,21.1-102.818,30.645-26.65,7.226-53.366,13.942-79.4,19.962-21.085,4.875-36.172,8-43.289,9.435Z" transform="translate(1432.644 2751.5)" fill="#f8f8f8"/>
                    </svg>                                 
                ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 414.869 397" preserveAspectRatio='none'>
                        <path id="Path_3" data-name="Path 3" d="M59.942,414.869s42.342-62.5,38.8-202.4S0,0,0,0L397,.005v414Z" transform="translate(0 397) rotate(-90)" fill="#f8f8f8"/>
                    </svg>                  
                )}
            </div>

            <Container className='md:h-screen'>
                <div className='flex flex-col w-full h-full z-40 py-24 md:flex-row md:justify-between' ref={ref}>
                    <div className="flex flex-col h-full gap-4 justify-center md:items-start md:w-1/3 z-40 mt-12" style={{ zIndex: 40 }}>
                        <motion.div className='flex z-40 justify-center md:justify-end'
                            animate={animation}
                        >
                            <img 
                                className='z-40 h-32 md:h-48 lg:h-64 my-auto'
                                src={require('../assets/faq.png').default} 
                                alt='policy'
                            />
                        </motion.div>
                    </div>

                    <motion.div className='relative flex flex-col w-full gap-16 md:justify-center md:w-2/3 md:ml-16 py-16 z-40'
                        animate={animation}
                    >
                        <h1 className='text-gray-50 font-heading text-3xl font-bold py-2'>Frequently Asked Questions</h1>
                        <ul className="relative questions flex flex-col gap-4 list-none h-max">
                            <AnimateSharedLayout>
                                {faqs.map((faq, index) => (
                                    <Question question={faq.question} answer={faq.answer} key={index}/>
                                ))}
                            </AnimateSharedLayout>
                        </ul>
                    </motion.div>
                </div>
            </Container>
        </section>
    )
}

export default FAQs
