import React, { useState } from 'react'
import { motion } from 'framer-motion';

const Image = ({imgUrl}) => (
    <motion.img className='h-6 lg:h-8' src={imgUrl} alt='home' layout />
)

const HeadLine = ({headline}) => (
    <motion.p className='text-xl text-gray-100'
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
        layout
    >
        {headline}
    </motion.p>
)

const Text = ({children}) => (
    <p className='text-gray-100' layout>
        {children}
    </p>
)

const PopCard = ({imgUrl, headline, text}) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleClick = (e) => {
        setIsOpen(!isOpen)
    }

    return (
        <motion.div 
            className={`
                border-2 p-4 border-wite rounded-xl cursor-pointer flex flex-col justify-self-start hover:border-green-400 shadow-lg gap-4 z-40
                ${isOpen && 'absolute w-auto border-green-400 bg-blue-800 bg-opacity-95 z-50'}
                lg:p-8 lg:justify-self-center lg:rounded-3xl
            `}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            transition={{ type: "tween", stiffness: 80 }}
            onClick={handleClick}
            layout
        >   
            <div className='flex gap-4' layout>
                <Image imgUrl={imgUrl}/>

                {isOpen && (
                    <HeadLine headline={headline} />
                )}
            </div>

            
            {isOpen && (
                <motion.div layout
                    initial={{ opacity: 0, x: '100%' }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.5 }}
                >
                    <Text>{text}</Text>
                </motion.div>
            )}
        </motion.div>
        
    )
}

export default PopCard
