import React, {useState } from 'react';
import { Container, SiteButton } from './App.elements';
import { AnimateSharedLayout } from 'framer-motion';
import PopCard from './PopCard';

const Home = ({isMobile}) => {
    const cards = [
        {
            imgUrl: require('../assets/user-icon.png').default,
            headline: 'For Physicians',
            text: `We offer affordable medical malpractice insurance rates for all physicians. We have many insurance companies that want to insure you for less than you're currently paying without compromising your coverage.`
        },
        {
            imgUrl: require('../assets/free-quote.png').default,
            headline: 'Request A Free Quote',
            text: 'At CoverDocs, we save you money on your medical malpractice insurance without compromising coverage. The quote is the first step to let you know that you are paying too much. Click the "Free Quote" button above and take the first step.'
        },
        {
            imgUrl: require('../assets/info-icon.png').default,
            headline: 'All Other Coverages',
            text: `At CoverDocs, we cover all physicians' needs. Medical malpractice is just one liability that you have. We can provide coverage for offices, medical and office equipment, workers compensation and autos.`
        }
    ]

    const scrollToContact = () => {
        const contact = document.getElementById('contact');
        window.scrollTo({
            top: contact.getBoundingClientRect().top,
            left: 0,
            behavior: 'smooth'
        })
    }
    
    return (
        <section className='relative flex py-12 min-w-screen h-screen bg-template' id='home'>
            <div className='flex h-96 w-3/4 absolute right-0 top-0 md:w-6/12 md:h-screen z-1 md:justify-end'>
                {!isMobile ? (
                    <svg xmlns="http://www.w3.org/2000/svg" height='100%' viewBox="0 0 730.268 771.013" preserveAspectRatio='none'>
                        <path id="Path_241" data-name="Path 241" d="M2802,0V771.006H2603.183s-592.231-358.613-526.333-566.935S2802,0,2802,0Z" transform="translate(-2071.732 0.007)" fill="#f8f8f8"/>
                    </svg>
                ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" height="100%" width="100%" viewBox="0 0 308.233 459.222" preserveAspectRatio='none'>
                        <path id="home-bg-mobile" d="M2363.677,0V308.227H1904.454s142.98-143.365,169.324-226.648S2363.677,0,2363.677,0Z" transform="translate(0.007 2363.677) rotate(-90)" fill="#f8f8f8"/>
                    </svg>
                )}
            </div>

            <Container className='h-full'>
                <div className='flex flex-col-reverse h-full justify-center w-full h-max z-40 md:flex-row md:justify-between'>

                    <div className='flex flex-col justify-center md:w-1/2 z-40'>
                        <div className="flex flex-col gap-4">
                            <h1 className='font-heading text-gray-50 text-2xl md:text-4xl'>Welcome to our <span className='text-green-400'>website.</span></h1>
                            <p className='text-gray-50 max-w-3/4 text-md md:text-lg md:w-max'>No business is complete without proper insurance coverage, and we're here to make sure you have the best protection possible. </p>
                            <SiteButton onClick={scrollToContact}>Free quote</SiteButton>
                        </div>

                        {/* WRAPPER RELATIVE TO HOLD THE CARD EXPAND */}
                        <div className='relative'>
                            <ul className="mt-12 grid grid-cols-home gap-4 w-max lg:w-full justify-start">
                                <AnimateSharedLayout>
                                    {cards.map((card, index) => (
                                        <PopCard {...card} key={ index }/>
                                    ))}
                                </AnimateSharedLayout>
                            </ul>
                        </div>
                    </div>

                    <div className='flex flex-col gap-8 z-40 justify-center'>
                        <img 
                            className='w-42 self-end md:w-80'
                            src={require('../assets/logo.png').default}
                        />
                        <img 
                            className='z-40 h-56 mr-12 md:h-96 mx-auto'
                            src={require('../assets/policy.png').default} 
                            alt='policy'
                        />
                    </div>

                </div>
            </Container>
        </section>
    )
}

export default Home
