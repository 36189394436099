import React from 'react';
import { motion } from 'framer-motion';

const MessageBox = () => {
    return (
        <motion.div 
            className='fixed top-24 right-4 py-2 px-4 rounded-3xl bg-green-400 shadow-lg z-50 text-gray-100'
            initial={{ x: '100vw' }}    
            animate={{ x: 0 }}
            exit={{ x: '100vw' }}
            transition={{ duration: 0.5 }}
        >
            <h1 className='text-2xl' >Thank you.</h1>
            <p className='text-lg'>Your message has been sent</p>
        </motion.div>
    )
}

export default MessageBox
