import React, { useEffect } from 'react'
import { Container } from './App.elements';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const HeadLine = ({children}) => (
    <h1 className={`text-gray-500 font-heading text-xl md:text-3xl font-bold py-2 md:py-4` }>
        {children}
    </h1>
)

const Text = ({children}) => (
    <p className='text-gray-500 text-md md:text-lg'>
        {children}
    </p>
)

const HandsakeIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 38.903 32" preserveAspectRatio='none'>
        <g id="handshake" transform="translate(-2 -5)">
            <path id="Path_30" data-name="Path 30" d="M10.645,9.323c0,6.746,5.611,7.417,7.629,7.1l7.294-1.157,8.74,7.884L17.479,36.054,4.314,22.29H2.023L2,5H17.129s-2.968,1.033-3.951,1.395S10.645,7.222,10.645,9.323Z" fill="#fff"/>
            <ellipse id="Ellipse_4" data-name="Ellipse 4" cx="3.5" cy="3" rx="3.5" ry="3" transform="translate(28.584 22)" fill="#fff"/>
            <path id="Path_31" data-name="Path 31" d="M17.2,8.72a3.6,3.6,0,1,1-7.2,0V8h4.533Z" transform="translate(3.527 1.323)" fill="#fff"/>
            <circle id="Ellipse_5" data-name="Ellipse 5" cx="2.5" cy="2.5" r="2.5" transform="translate(23.584 5)" fill="#fff"/>
            <ellipse id="Ellipse_6" data-name="Ellipse 6" cx="2.5" cy="3" rx="2.5" ry="3" transform="translate(25.584 25)" fill="#fff"/>
            <circle id="Ellipse_7" data-name="Ellipse 7" cx="3" cy="3" r="3" transform="translate(20.584 28)" fill="#fff"/>
            <circle id="Ellipse_8" data-name="Ellipse 8" cx="3" cy="3" r="3" transform="translate(16.584 31)" fill="#fff"/>
            <path id="Path_32" data-name="Path 32" d="M37.376,25.141V7.851L31.613,9.292,24.2,5.246l-1.985-.177L10,9.292l3.967,4.3,9-1.428s7.564,6.77,8.886,8.092c1.268,1.268,2.521,2.223,2.621,4.882Z" transform="translate(3.527 0.03)" fill="#fff"/>
        </g>
    </svg>
)

const QuestionIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 36.518 36.518" preserveAspectRatio='none'>
        <path id="_3671842_question_icon" data-name="3671842_question_icon" d="M18.259,36.518A18.259,18.259,0,1,1,36.518,18.259,18.259,18.259,0,0,1,18.259,36.518Zm3.652-23.736a3.265,3.265,0,0,1-.767,1.826l-2.885,2.885a6.959,6.959,0,0,0-1.826,4.419v1.826h3.652V21.911a3.212,3.212,0,0,1,.767-1.826L23.736,17.2a6.959,6.959,0,0,0,1.826-4.419,7.3,7.3,0,0,0-14.607,0h3.652a3.652,3.652,0,0,1,7.3,0ZM16.433,27.388V31.04h3.652V27.388Z" fill="#f8f8f8"/>
    </svg>
)

const Card = ({className, svg, headline, text, imgUrl}) => (
    <motion.div className={`rounded-3xl bg-gray-100 shadow-lg border-2 border-blue-800 hover:border-green-400 ${className}`}
        whileHover={{ scale: 1.1 }}
    >
        <div className='flex gap-4 items-center bg-blue-800 p-4 rounded-tr-3xl rounded-tl-3xl'>
            <div className='h-6 w-max'>
                {svg}
            </div>
            <p className='text-gray-50 font-heading text-md md:text-xl uppercase'>{headline}</p>
        </div>
        <p className='p-4 text-gray-500 text-md md:text-lg'>{text}</p>
    </motion.div>
)

const content1 = [
    {
        headline: 'A Few Words About Us',
        text: `Five years ago, we were reviewing a medical facility's business insurance. Their administrator had asked if we could find them more affordable rates on their medical malpractice insurance. From that point, it was clear that no one does what the medical profession needs and deserves; an insurance agency that understands the medical field. We cover doctors and their facilities.`
    },
    {
        headline: 'Our Principles',
        text: `We believe that you should get a good product for a fair price. With the help of you, our policyholders, we are trying to bring back competition to the medical malpractice insurance marketplace. As your costs rise, the price you pay for your insurance shouldn't have to.`    
    }
]

const content2 = [
    {
        headline: 'What we Offer',
        text: 'CoverDocs is a full service insurance agency dedicated to ensuring that health care providers and organizations select the best and most affordable medical malpractice insurance available in their area. We provide coverage for your business, office, staff, equipment, and of course, medical malpractice insurance.',
        svg: <HandsakeIcon />
    },
    {
        headline: 'WHY CHOOSE COVERDOCS?',
        text: 'In each state, there are one or two insurance companies that hold at least 75% of the medical malpractice insurance policies written. In any industry, most would consider that close to a monopoly. We are one of the only insurance agencies actively seeking physicians and medical facilities to show them that they have a choice. With the new rules and regulations of the latest healthcare overhaul, the medical industry has taken some major cuts. But the cost of doing business has only increased. We are here to help you where it costs the most. Simply, we have rates that are less than you currently pay without compromising your coverage and safety.',
        svg: <QuestionIcon />
    }
]

const About = ({isMobile}) => {
    const {ref, inView} = useInView({ threshold: 0.2 })
    const animation = useAnimation();

    useEffect(() => {
        if(inView){
            //if element is in view
            animation.start({
                opacity: 1,
                transition: { duration: 0.7 }
            })
        }else{
            animation.start({ 
                opacity: 0
            })
        }
    }, [inView]);

    
    return (
        <section className='relative min-w-screen min-h-screen bg-template-top' id='about'>
            <div className='flex w-full absolute h-2/4 left-0 top-0 z-0 md:w-6/12 md:h-full md:flex-col md:justify-end'>
                {!isMobile ? (
                    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 801.356 772.148" preserveAspectRatio='none'>
                        <path id="Path_3" data-name="Path 3" d="M2264.122,772.6,2126.6-.649l-660.133-.9-3.7,773.5Z" transform="translate(-1462.766 1.553)" fill="#f8f8f8"/>
                    </svg>                  
                ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 414.081 654.85" preserveAspectRatio='none'>
                        <path id="Path_3" data-name="Path 3" d="M0,411.949,94.99,0,653.85.018l-.376,413.062Z" transform="translate(0.5 654.35) rotate(-90)" fill="#f8f8f8" stroke="#707070" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                    </svg>
                )}
            </div>

            <Container>
                <div className='flex flex-col w-full h-auto z-30 py-12 md:flex-row md:justify-between' ref={ref}>
                    <motion.div className="flex flex-col gap-4 justify-center md:w-1/3 z-30 mt-12"
                        animate={animation}
                    >
                        {content1.map(content => (
                            <>
                                <HeadLine>{content.headline}</HeadLine>
                                <Text>{content.text}</Text>
                            </>
                        ))}
                    </motion.div>

                    <motion.div className='flex flex-col w-full items-end justify-center gap-16 md:w-2/3 md:ml-16 py-16 z-30'
                        animate={animation}
                    >
                        <Card className='md:w-2/3' {...content2[0]} />
                        <Card className='md:w-3/3' {...content2[1]} />
                    </motion.div>
                </div>
            </Container>

        </section>
    )
}

export default About
