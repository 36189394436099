import React from 'react'

const UnderConstruction = () => {
    return (
        <section className='w-screen h-screen bg-gradient-to-r from-blue-400 via-blue-600 to-indigo-500'>
            <div className="container mx-auto px-4 flex flex-col h-full w-full items-center justify-center gap-4">
                {/* <img src={require('./assets/coverdocs-logo.png').default}/> */}
                <img 
                    className='px-8 h-56'
                    src={require('../assets/building.svg').default}
                /> 
                <h1 className='text-3xl text-gray-200 lg:text-5xl'>Page under construction</h1>
                <p className='text-gray-200 lg:text-2xl'>Please come back some other time</p>
            </div>
        </section>
    )
}

export default UnderConstruction
