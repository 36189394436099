import React, { useState, useEffect } from 'react';
import { Container, SiteButton } from './App.elements';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import emailjs from 'emailjs-com';
import { siteConfig } from '../config';


const Input = (props) => (
    <input 
        {...props}
        className={`p-2 outline-none bg-transparent border-b-2 border-blue-400 w-full focus:border-green-400 text-gray-50 placeholder-blue-400 ${props.className && props.className}`}
    />
)

const TextArea = (props) => (
    <textarea 
        {...props}
        className={`p-2 outline-none bg-transparent border-2 border-blue-400 rounded-2xl w-full focus:border-green-400 text-gray-50 h-56 ${props.className && props.className}`}
    />
)

const Contact = ({isMobile, showMessage}) => {
    const [email, setEmail] = useState({
        sender_name: '',
        sender_email: '',
        sender_phone: '',
        message: ''
        
    })
    const {ref, inView} = useInView({ threshold: 0.2 })
    const animation = useAnimation();

    const handleChange = (e) => {
        setEmail({
            ...email,
            [e.target.name]: e.target.value
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        //get credentials
        const { SERVICE_ID, TEMPLATE_ID, USER_ID } = siteConfig;
        emailjs.send(SERVICE_ID, TEMPLATE_ID, email, USER_ID )
        .then(result => {
            showMessage(true)
            setTimeout(() => showMessage(false), 3000)
            e.target.reset();
        })
        .catch(error => {
            console.error(error);
        })
    }

    useEffect(() => {
        if(inView){
            //if element is in view
            animation.start({
                opacity: 1,
                transition: { duration: 0.7 }
            })
        }else{
            animation.start({ 
                opacity: 0
            })
        }
    }, [inView]);
    
    return (
        <section className='relative min-h-screen w-screen py-12 md:h-screen bg-template-top overflow-hidden' id='contact'>
            <div className='flex bottom-0 w-full h-full absolute z-0 md:right-0 md:top-0 md:h-full z-0'>
                {!isMobile ? (
                    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1372.622 763.615" preserveAspectRatio='none'>
                        <path id="Exclusion_1" data-name="Exclusion 1" d="M-63.378-3121l-225.244-.5H-70.5v-1.358c4.235,1.125,6.675,1.743,7.121,1.855Zm-226.145-.5H-1435.5v-2.544l1145.976,2.542Zm219.023-1.358h0c-16.52-4.391-61.169-16.742-118.825-36.516-32.416-11.117-64.183-22.951-94.417-35.171-36.486-14.747-71.138-30.223-102.991-46-17.814-8.822-35.057-17.891-51.251-26.954-17.3-9.684-33.87-19.621-49.243-29.535-16.325-10.527-31.752-21.318-45.851-32.073a534.563,534.563,0,0,1-41.075-34.57,391.045,391.045,0,0,1-34.916-37.025,285.125,285.125,0,0,1-27.372-39.438,229.6,229.6,0,0,1-10.424-20.611,203.527,203.527,0,0,1-8.02-21.2,186.016,186.016,0,0,1-5.442-21.78,176.686,176.686,0,0,1-2.691-22.357c-3.217-57.905-13.015-112.743-29.124-162.99-12.961-40.427-30.029-78.036-50.73-111.782a433.88,433.88,0,0,0-26.189-38.071c-7.423-9.619-15.114-18.679-22.858-26.929-13.575-14.462-23.043-21.85-24.078-22.645H-70.5Zm-1365-1.186h-.5v-764.57H-816.15l.029.022.121.093h-619.5Z" transform="translate(1436 3888.615)" fill="#f8f8f8"/>
                    </svg>                         
                ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 412 734.844" preserveAspectRatio='none'>
                        <path id="Exclusion_2" data-name="Exclusion 2" d="M-2479-3153.156h-.483l-2.156-735H-2894V-3889h412.358l0,.844h2.64Zm-.483,0H-2894v-201.153c.042.02,5.469,2.661,15.7,6.706,9.389,3.713,24.815,9.381,44.986,15.3a681.673,681.673,0,0,0,71.113,16.655,756.594,756.594,0,0,0,94.078,10.78c27.224,1.415,48.7,6.6,69.624,16.823a222.419,222.419,0,0,1,30.176,18.13c9.028,6.314,18.808,13.775,32.7,24.95a155.3,155.3,0,0,1,28.667,30.347,169.408,169.408,0,0,1,17.088,29.782,165.987,165.987,0,0,1,8.245,22.671c1.54,5.506,2.116,8.87,2.14,9.01Z" transform="translate(2894 3888)" fill="#f8f8f8"/>
                    </svg>          
                )}
            </div>

            <Container>
                <div className='flex flex-col w-full z-40 md:flex-row md:justify-between md:h-screen' ref={ref}>
                    <motion.form className="flex flex-col gap-4 w-full z-40 md:justify-center md:w-1/2 h-full"
                        animate={animation}
                        onSubmit={handleSubmit}
                    >
                        <h1 className='font-heading text-gray-50 text-2xl md:text-4xl'>Contact Us</h1>
                        <p className='text-gray-50 text-md md:text-lg md:w-max md:max-w-3/4'>Ask us any questions that you may have. Or ask us to review your current coverage and we will tell you how much you are currently overspending.</p>

                        <div className='grid grid-cols-2 gap-4 text-xl'>
                            <Input 
                                type='text' 
                                name='sender_name' 
                                placeHolder='Enter your name' 
                                className='col-start-1 col-span-full lg:col-span-full'
                                onChange={handleChange}
                                required
                            />

                            <Input 
                                type='email' 
                                name='sender_email' 
                                placeHolder='Enter your email' 
                                className='col-start-1 col-span-full lg:col-span-1'
                                onChange={handleChange}
                                required
                            />  

                            <Input 
                                type='phoe' 
                                name='sender_phone' 
                                placeHolder='Enter your phone' 
                                className='col-start-1 col-span-full lg:col-start-2 lg:col-span-1'
                                onChange={handleChange}
                                required
                            /> 

                            <TextArea 
                                name='message'
                                placeHolder='Message'
                                className='col-start-1 col-span-full lg:col-span-full'
                                onChange={handleChange}
                                required
                            />
                        </div>

                        <SiteButton>Send Message</SiteButton>
                    </motion.form>

                    <motion.div className='flex z-40 md:justify-end'
                        animate={animation}
                    >
                        <img 
                            className='z-40 h-36 md:h-44 md:my-auto'
                            src={require('../assets/contact.png').default} 
                            alt='policy'
                        />
                    </motion.div>
                </div>
            </Container>
            
        </section >
    )
}

export default Contact
