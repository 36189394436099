import React from 'react';
import { motion } from 'framer-motion';
import { Container } from './App.elements';

const Article = ({children}) => (
    <article className='flex flex-col gap-2 md:pt-32'>
        {children}
    </article>
)
const HeadLine = ({children}) => (
    <h1 className={`text-green-400 font-heading text-xl md:text-3xl uppercase` }>
        {children}
    </h1>
)

const Text = ({children}) => (
    <p className='text-gray-100 text-md md:text-lg'>{children}</p>
)

const List = ({children}) => (
    <ul className='ml-8'>
        {children}
    </ul>
)

const Item = ({children}) => (
    <li className='text-gray-100 text-md md:text-lg font-body'>{children}</li>
)

const SubHeadLine = ({children}) => (
    <h2 className='text-green-400 font-heading text-md md:text-lg uppercase'>{children}</h2>
)

const PrivacyPolicy = () => {
    return (
        <motion.section className='relative flex py-12 min-w-screen h-max bg-template' id='privacy_policy'>
            <Container className='flex jutify-center items-center'>
                <Article>
                    <div className='w-full p-4 bg-gray-50 bg-opacity-30 rounded-3xl flex justify-center shadow-lg md:w-min md:mx-auto'>
                        <img className='' src={require('../assets/logo.png').default} alt='logo' />
                    </div>
                    <HeadLine>Privacy Policy</HeadLine>
                    <Text>We know that privacy is very important to most people and we want to let you know that it is important to us, too. The following is a summary of our privacy practices to let you know:</Text>
                    <List>
                        <Item>What information we might collect.</Item>
                        <Item>What we do with the information we collect.</Item>
                        <Item>What security measures we have in place.</Item>
                    </List>
                    <SubHeadLine>General Information</SubHeadLine>
                    <Text>
                        We respect your right to privacy. Whether you are a current customer of CoverDocs Insurance Group or if you are just visiting this site, we assure you that we do not collect personal information about you unless you provide it to us. CoverDocs Insurance Group's web site contains links to other sites. We cannot comment on the privacy practices of these other sites and strongly urge you to review the privacy policy on each site you visit.
                    </Text>
                    <SubHeadLine>Your Information</SubHeadLine>
                    <Text>
                        Personal information is not collected unless you give it to us. If you are simply browsing this site, we do not collect any personal information. From time to time, we may compile statistics on the use of our site. We may monitor statistics such as how many visitors we've had or which browsers people use but this data does not identify anyone personall.
                    </Text>
                    <SubHeadLine>E-Mails</SubHeadLine>
                    <Text>
                        CoverDocs Insurance Group uses links through its site to allow you to elect to contact us via e-mail. Although we will ask you to call or write us about many topics we consider too sensitive to be done via e-mail, we do accept questions and welcome your comments and suggestions. In addition, you may also be offered the opportunity to have one of our representatives contact you. To better serve you in this regard, we may require that minimal personal information be provided to us, such as your name and telephone number. We may keep this information on file to help us help you in the future. We may also share this information with a subsidiary, business partner, representative or affiliate of CoverDocs Insurance Group so that they may contact you about other goods and services or to assist you in the support, renewal or purchase of CoverDocs Insurance Group products and services.
                    </Text>
                </Article>
            </Container>
        </motion.section>
    )
}

export default PrivacyPolicy
